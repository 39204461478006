@import '@lib/_vars.scss';

.alert {
  border: 1.4px solid map-get($colors, 'alert');
}

.divider {
  background-color: rgba(255, 255, 255, 0.12);
  margin: 8px !important;
  width: 100%;
}
