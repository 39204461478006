@import '@lib/_vars.scss';

.alertBox {
  background-color: map-get($colors, 'alert');
  color: white;
  padding: 8px 0;
  text-align: center;
}

.logo {
  align-content: center;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
}
