@import '@lib/_vars.scss';

$transitionDuration: 0.05s;

.roomName {
  align-self: center;
  margin-right: 8px;
}

.roomDetails {
  font-size: 80%;
  align-self: center;
}

.roomStatus {
  align-items: center;
  align-self: center;
  display: flex;
  font-size: 60%;
  margin-left: 8px;

  @each $name, $color in $colors {
    &.#{$name} {
      color: $color;
    }
  }
}

.settings {
  padding: 0;

  & > * {
    color: white;
  }
}

.alertButton {
  align-self: center;
  border-radius: 0 !important;
  color: map-get($colors, alert) !important;
  height: 75%;
  line-height: 1;
  margin: 0 8px !important;
  padding: 0 8px;
}

.imgDialog {
  align-items: center;
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
  overflow: hidden;

  * {
    color: white;
  }

  .center {
    text-align: center;
    font-weight: normal;
  }

  .imgContainer {
    transition: width $transitionDuration linear,
      height $transitionDuration linear;

    img {
      transition: transform $transitionDuration linear;
      max-height: 400px;
      max-width: 80vh;
    }

    &.rotated {
      width: 400px;
      height: 80vh;

      img {
        transform: rotate(90deg) translate(25%, 40%);
      }
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      display: inline-block;
      align-self: center;
    }
  }

  .rotateIcon {
    & > span {
      display: flex;
      position: fixed;
      bottom: 5vw;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.vitalTable * {
  color: white !important;
}
