.returnButton {
  max-width: 225px;
  margin: 8px 0 !important;
}

.alertDetails {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }

  hr {
    margin-top: 16px;
    width: 100%;
  }
}

.vitalReading {
  max-width: 100%;
  border: 2px solid gray;
  border-radius: 2px;
  margin: 16px 0;

  img {
    max-width: 100%;
  }
}
